<template>
  <v-data-table
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    calculate-widths
    disable-pagination
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    :headers="headers"
    hide-default-footer
    :loading="statusRequest.value === 'loading-getListOffBiz'"
    loading-text="Đang tải dữ liệu"
    :items="listOffBiz"
    item-key="id"
    @click:row="viewDetail($event)"
  >
    <template v-slot:[`item.code`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.code)"
          >
            {{ item.code }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.serial.serial_number`]="{ item }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <div
            class="copied-label"
            v-bind="attrs"
            v-on="on"
            @click.stop="copyToClipboard(item.serial.serial_number)"
          >
            {{ item.serial.serial_number }}
          </div>
        </template>
        <span>Sao chép</span>
      </v-tooltip>
    </template>

    <template v-slot:[`item.serial.product_name`]="{ item }">
      <div class="my-1">
        <div class="">
          {{ item.serial.product_name }}
        </div>
        <div
          class="sub--text text-subtitle-2 font-weight-light grey--text text--darken-2"
          v-html="item.serial.option_name"
        ></div>
      </div>
    </template>

    <template v-slot:[`item.step`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="findedStatus(stepList, item.step).color"
        small
        outlined
      >
        {{ findedStatus(stepList, item.step).label }}
      </v-chip>
    </template>

    <template v-slot:[`item.selected_action`]="{ item }">
      <v-chip
        class="font-weight-bold"
        :color="findedStatus(ACTION_STATUS, item.selected_action).color"
        small
        outlined
      >
        {{ findedStatus(ACTION_STATUS, item.selected_action).label }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import {
  ACTION_STATUS,
  findedStatus
} from "@/modules/NotSoldWarehouse/utils/static-data";

export default {
  data() {
    return {
      ACTION_STATUS: ACTION_STATUS,
      headers: [
        {
          text: "Mã phiếu",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Sản phẩm",
          align: "start",
          sortable: false,
          value: "serial.product_name"
        },
        {
          text: "Serial",
          align: "start",
          sortable: false,
          value: "serial.serial_number"
        },
        {
          text: "Người tiếp nhận",
          align: "start",
          sortable: false,
          value: "assigned_to_user.name"
        },
        {
          text: "Tình trạng máy",
          align: "start",
          sortable: false,
          value: "tinh_trang_may"
        },
        {
          text: "Thời gian xử lý",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Trạng thái",
          align: "start",
          sortable: false,
          value: "step"
        },
        {
          text: "Hành động",
          align: "start",
          sortable: false,
          value: "selected_action"
        }
      ],
      stepList: [
        { value: 1, label: "Chờ kiểm tra", color: "amber" },
        { value: 2, label: "Đang kiểm tra", color: "deep-purple" },
        { value: 3, label: "Đã kiểm tra xong", color: "green" }
      ]
    };
  },
  computed: {
    listOffBiz() {
      return this.$store.getters["RETURN_SERIAL_NOTE/listOffBiz"];
    },
    statusRequest() {
      return this.$store.getters["RETURN_SERIAL_NOTE/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    findedStatus(statusList, statusValue) {
      return findedStatus(statusList, statusValue);
    },

    async viewDetail(item) {
      await this.$store.dispatch("RETURN_SERIAL_NOTE/getOffBizById", item.id);

      if (this.statusRequest.value === "success-getOffBizById") {
        const offBizDetail = await this.$store.getters[
          "RETURN_SERIAL_NOTE/offBizDetail"
        ];
        await this.$router.push({
          name: "not-sold-warehouse_return-serial-notes-detail",
          params: {
            offBizId: offBizDetail.id
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }
  .copied-label {
    cursor: pointer;
    display: inline-block;
  }
  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
  .sub--text {
    ::v-deep p {
      margin-bottom: 0;
    }
  }
}
</style>
